import { Pattern, Gradient } from "fabric/fabric-impl";

export interface DesignImage {
    id: string;
    url: string;
}

export interface CustomFabricObject extends fabric.Object {
    src?: string;
}

export interface UploadFile {
    file: any;
    clientId: string;
}

export interface PositionImageItem {
    position: string;
    images?: string[]; // Consider using a more specific type if possible
    isActive?: boolean;
}

export interface ImageObject {
    url: string;
    coordinates?: {
        x?: number;
        y?: number;
    };
    rotation?: number;
    scaleX?: number;
    scaleY?: number;
}

export interface EditableCd {
  url: string;
  ts: number;
}

export interface VariantColors {
  _id: string;
  gtin: string;
  vendorStyleId : number;
  brand: string;
  style: string;
  categories: string[];
  colorFamily: string;
  colorHex: string;
  colorName: string;
  createdAt: Date,
  description: string;
  images: VariantImages;
  price: number;
  size: string;
  title: string;
  unitWeight: number;
  updatedAt: Date;
  vendorName: string;
}

export interface Designs {
  _id?: string;
  name?: string;
  image?: string;
  positions?: Partial<DesignToolPosition>[];
  products?: Partial<DesignToolProduct>[];
  clientId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  createdBy?: DesignToolUser;
}
export interface DesignToolPosition {
    mockupUrl?: string;
    position: string;
    url?: string;
    canvas?: PositionDesign[];
    notes?: string;
    decorationType?: DecorationTypeEnum;
}
export interface PositionDesign {
  stitchCount: number;
// if we are in a mobile device, where the rendered product image width is 390px, we save that value in imageClientWidth. So every element in the canvas keeps a reference to the rendered product image width. This way, we can do size and position conversions on a different viewport size
  imageClientWidth?: number;
  cornerStyle?: "circle" | "rect";
  cornerColor?: string;
  fill?: string | Pattern | Gradient;
  borderColor?: string;
  borderDashArray?: any;
  rotatingPointOffset?: number;
  shouldResize?: boolean;
  name?: string;
  url: string;
  coordinates?: {
      x?: number;
      y?: number;
  };
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
  scaledToMobile?: boolean;
  style?: string;
  cornerSize?: number;
  color?: string;
  dashArray?: number[];
  offset?: number;
  padding?: number;
  editable?: boolean;
  colorCount?: number;
  decorationType?: string;
  qty?: number;
  originX?: string;
  originY?: string;
  width?: number;
  height?: number;
}
export interface DesignToolUser {
    id: number;
    firstName: string;
    lastName?: string;
    email: string;
}

export interface DesignToolVariant {
    color?: string;
    qty?: number;
    size?: string;
    price?: number;
}

export interface DesignToolProduct {
    title: string;
    brand: string;
    style: string;
    vendorStyleId: number;
    vendorName: string;
    decoratedImages?: string[];
    variants?: DesignToolVariant[];
}

// export interface DesignToolPosition {
//     position: string;
//     placementType?: string;
//     image?: string;
//     colorCount?: number;
//     qty?: number;
// }
export interface VariantImages {
    front: string;
    back: string;
    side: string;
}

export interface S3Files {
    Key: string,
    LastModified: string,
    ETag: string,
    ChecksumAlgorithm: any,
    Size: number,
    StorageClass: string,
    Owner: {
        ID: string
    }
}

export interface TransformedFiles {
    dragOffset?: { x: number; y: number;};
    width?: number;
    height?: number;
    imageHeight?: number;
    imageWidth?: number;
    hasTransparentBackground?: boolean;
    imageUrl: string;
    id: string;
    name?: string;
    fromDrop?: boolean;
}

export interface PositionsDesign {
    position: string,
    images: any[],
    isActive: boolean,
}

export interface Dimensions {
  width: number;
  height: number;
  top: number;
  left: number;
  scaleFactor: number;
}

export interface CanvasDimensionsColor {
  name: string;
  borderColor: string;
  front?: Dimensions;
  back?: Dimensions;
  side?: Dimensions;
}

export interface CanvasDimensions {
  brand: string;
  style: string;
  colors: CanvasDimensionsColor[]
}
export interface DesignToolCanvas {
    printingCanvas: any;
    brand: string;
    style: string;
    color: string;
    borderColor: string;
    front: Dimensions;
    back: Dimensions;
    decorationTypes: string[];
}

export interface DesignToolAvailable {
    brand: string;
    style: string;
    color: string;
}


export enum DecorationTypeEnum {
    Embroidery = "Embroidery",
    ScreenPrint = "Screen Print",
    Digital = 'Digital',
    Transfers = 'Transfers',
    Print = "Print"
}