import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromOrders from '@core/state/order/order.reducers';

export const selectOrderState = createFeatureSelector<fromOrders.State>('order');
export const selectOrders = createSelector(selectOrderState, state => state.orders); // get all orders
export const selectOrdersDetail = createSelector(selectOrderState, state => state.ordersDetail); // get all order detail
export const selectOrdersDetailV2 = createSelector(selectOrderState, state => state.ordersDetailV2); // get all order detail v2
export const selectDesignImages = createSelector(selectOrderState, state => state.designImages); // get all design images

export const selectOrderSizeColors = createSelector(selectOrdersDetail, (data) => {
    let results: any;
    let design: any;
    let products = [];
    if(data) {
        results = data
        design = results.data.designs

        design.map(product => {
            products.push(product.products)
        })
    }
    return products
})

export const selectOrderSizeColorsDetail = createSelector(selectOrdersDetail, (data) => {
    let results: any;
    let design: any;
    let products = [];
    let sizeColors = [];
    if(data) {
        results = data
        design = results.data.designs

        design.map(product => {
            products.push(product.products)
        })

        if(products.length > 0) {
            Object.values(products).forEach(sc => {
                sc.map(scData => {
                    sizeColors.push(scData.size_colors)
                })
            })

        }

    }
    return sizeColors
})

export const selectOrderHeaders = createSelector(selectOrderState, state => state.headers);

export const selectOrderTableHeaders = createSelector(selectOrderHeaders, (data) => {
    let result = []
    if(data){
        result.push(
            { title: 'All', count: (data.estimateCount + data.completedCount + data.productionCount) }, 
            { title: 'Estimate', count: data.estimateCount }, 
            { title: 'Production', count: data.productionCount }, 
            { title: 'Completed', count: data.completedCount }
        )
    }
    return result;
})
// export const selectOrderCounts = createSelector(selectOrders, (data) => {
//     let results: number | 0;
//     if(data){
       
//         results = data.length;
//     }
//     return results
// })

// export const selectAllOrders = createSelector(selectOrders, (data) => {
//     let results: any;
//     if(data){
//         results = data.map(filteredO => {
//             return {
//                 orderId : filteredO.display_number,
//                 jobName : filteredO.name,
//                 po : filteredO.custom_po,
//                 orderPlaced : filteredO.created_date,
//                 garmentArrivalDate : filteredO.garment_date,
//                 shipDate : filteredO.date_shipped,
//                 inHandsDate : filteredO.in_hands_by,
//                 status : filteredO.approval_id.cpstatus.label,
//                 mockup_img: filteredO.mockup_img
//             }
//         })
//         results.sort((a,b) => {
//             if(a.orderId > b.orderId) return -1;
//             if(a.orderId < b.orderId) return 1;
//             return 0;
//         })
//     }
    
//     return results
// })
export const selectProductionOrders = createSelector(selectOrders, (data) => {
    let results: any;
    if(data){
        // results = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'In Production' || status == 'Art'
        // }).map(filteredO => {
        //     return {
        //         orderId : filteredO.display_number,
        //         jobName : filteredO.name,
        //         po : filteredO.custom_po,
        //         orderPlaced : filteredO.created_date,
        //         garmentArrivalDate : filteredO.garment_date,
        //         shipDate : filteredO.date_shipped,
        //         inHandsDate : filteredO.in_hands_by,
        //         status : filteredO.approval_id.cpstatus.label,
        //         mockup_img: filteredO.mockup_img
        //     }
        // })
        results.sort((a,b) => {
            if(a.orderId > b.orderId) return -1;
            if(a.orderId < b.orderId) return 1;
            return 0;
        })
    }
    return results
})
export const selectProductionOrderCounts = createSelector(selectOrders, (data) => {
    let results: number | 0;
    if(data){
        // let orders = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'In Production' || status == 'Art'
        // })
        // results = orders.length;
    }
    return results
})
export const selectEstimateOrders = createSelector(selectOrders, (data) => {
    let results: any;
    if(data){
        // results = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'Under Review'
        // }).map(filteredO => {
        //     return {
        //         orderId : filteredO.display_number,
        //         jobName : filteredO.name,
        //         po : filteredO.custom_po,
        //         orderPlaced : filteredO.created_date,
        //         garmentArrivalDate : filteredO.garment_date,
        //         shipDate : filteredO.date_shipped,
        //         inHandsDate : filteredO.in_hands_by,
        //         status : filteredO.approval_id.cpstatus.label,
        //         mockup_img: filteredO.mockup_img
        //     }
        // })
        results.sort((a,b) => {
            if(a.orderId > b.orderId) return -1;
            if(a.orderId < b.orderId) return 1;
            return 0;
        })
    }
    return results
})
export const selectEstimateOrderCounts = createSelector(selectOrders, (data) => {
    let results: number | 0;
    if(data){
        // let orders = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'Under Review'
        // })
        // results = orders.length;
    }
    return results
})
export const selectCompletedOrders = createSelector(selectOrders, (data) => {
    let results: any;
    if(data){
        // results = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'Shipped' || status == 'Payment' || status == 'Fulfilled'
        // }).map(filteredO => {
        //     return {
        //         orderId : filteredO.display_number,
        //         jobName : filteredO.name,
        //         po : filteredO.custom_po,
        //         orderPlaced : filteredO.created_date,
        //         garmentArrivalDate : filteredO.garment_date,
        //         shipDate : filteredO.date_shipped,
        //         inHandsDate : filteredO.in_hands_by,
        //         status : filteredO.approval_id.cpstatus.label,
        //         mockup_img: filteredO.mockup_img
        //     }
        // })
        results.sort((a,b) => {
            if(a.orderId > b.orderId) return -1;
            if(a.orderId < b.orderId) return 1;
            return 0;
        })
    }
    return results
})

export const selectCompletedOrderCounts = createSelector(selectOrders, (data) => {
    let results:number | 0;
    if(data){
        // let orders = data.filter(o => {
        //     let status = o.approval_id['cpstatus']['label']
        //     return status == 'Shipped' || status == 'Payment' || status == 'Fulfilled'
        // })
        // results = orders.length;
    }
    return results
})

// export const selectOrderHeaderCounts = createSelector(selectOrders, (data) => {
//     let headers = []
//     if(data){      
//         let allOrderCounts = data.estimateCount + data.productionCount + data.completedCount;
//         let estimateOrderCounts = data.estimateCount;
//         let productionOrderCounts = data.productionCount;
//         let completedOrderCounts = data.completedCount;

//         headers = [ {title: 'All', counts: allOrderCounts},{title: 'Estimates', counts: estimateOrderCounts}, {title: 'Production', counts: productionOrderCounts},  {title: 'Completed', counts: completedOrderCounts} ]
        
//         return headers;
//     }
// })


export const selectAllOrders = createSelector(selectOrders, (data) => {
    let result: any;
    if(data){
        let orders = data;
        result = orders.map(filteredO => {
            return {
                orderId : filteredO.display_number,
                jobName : filteredO.name,
                po : filteredO.custom_po,
                orderPlaced : filteredO.created_date,
                garmentArrivalDate : filteredO.garment_date,
                shipDate : filteredO.date_shipped,
                inHandsDate : filteredO.in_hands_by,
                status : filteredO.approval_id.cpstatus.label,
                mockup_img: filteredO.mockup_img
            }
        })
        result.sort((a,b) => {
            if(a.orderId > b.orderId) return -1;
            if(a.orderId < b.orderId) return 1;
            return 0;
        })
        return result;
    }
})

