import { Component, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { CartVariantTableModalComponent } from '../cart-variant-table-modal/cart-variant-table-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromCatalog from '@core/state/catalog/catalog.selectors';
import { Subject, takeUntil } from 'rxjs';
import { HelperService } from '@core/services/api/helper.service';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import { ContentfulService } from '@core/services/cms/contentful.service';
import { Cart, CartDesign } from '@core/models/catalog';
import { MetaService } from '@core/services/seo/meta-tag-service';
import { DataLayerService } from '@core/services/seo/data-layer.service';

@Component({
  selector: 'app-cart-base',
  templateUrl: './cart-base.component.html',
  styleUrls: ['./cart-base.component.scss']
})
export class CartBaseComponent implements OnInit {
  cart: Cart;
  cartId: string;
  subtotal: number = 0;
  tax: number = 0;
  grandTotal: number = 0;
  section0: any;
  section1: any;
  section2: any;
  section3: any;
  productSectionName0: any;
  productSectionName1: any;
  productSectionName2: any;
  productSectionName3: any;
  destroy$ = new Subject<boolean>()
  screenWidth: number;

  constructor(
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private store: Store,
    private helper: HelperService,
    private dataLayerService: DataLayerService,
    protected router: Router,
    private contentfulService: ContentfulService,
    private metaService: MetaService
  ) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    this.route.params.subscribe(params => {
      this.cartId = params['id']; // Access the 'id' parameter from the route
      this.store.pipe(select(fromCatalog.selectCartById(this.cartId)), takeUntil(this.destroy$)).subscribe((cart => { 
        this.cart = cart
        this.metaService.updateMetaTags(
          `Cart: ${this.cart.cartName ? this.cart.cartName : ''}`,
          `View your cart in the Catalog within the Culture Studio Platform`,
          `View your cart in the Catalog within the Culture Studio Platform`,
          `https://img.stokkup.com/app/cs-circle-logo.png`,
        )
      }));
    });

    this.contentfulService.getDesignToolHome().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.section0 = data;
      this.productSectionName0 = this.section0 && this.section0[0] ? this.section0[0].sectionName : '';
    })

    this.contentfulService.getProductSection1().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.section1 = data;
      this.productSectionName1 = this.section1 && this.section1[0] ? this.section1[0].sectionName : '';
    })

    this.contentfulService.getProductSection2().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.section2 = data;
      this.productSectionName2 = this.section2 && this.section2[0] ? this.section2[0].sectionName : '';
    })

    this.contentfulService.getProductSection3().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.section3 = data;
      this.productSectionName3 = this.section3 && this.section3[0] ? this.section3[0].sectionName : '';
    })
  }

  openVariantModal(cart: Cart, design: CartDesign) {
    this.modalService.open(CartVariantTableModalComponent, {
      modalClass: design?.products?.variants?.length > 4 ? 'modal-lg' : 'modal-sm',
      data: {
        cart: cart,
        design: design
      }
    });
  }

  getProductSizeQtys(variants) {
    let sum = 0;

    for (const variant of variants) {
      if (variant.qty) {
        sum += variant.qty;
      }
    }

    return sum;
  }

  getProductPrice(variants) {
    let sum = 0;

    for (const variant of variants) {
      if (variant.price) {
        sum += (variant.price * variant.qty);
      }
    }

    return sum;
  }

  // Calculate the grand subtotal for all products
  calculateGrandSubtotal() {
    this.subtotal = 0;
    if (this.cart && this.cart.designs) {
      for (const design of this.cart.designs) {
        this.subtotal += this.getProductPrice(design.products.variants);
      }
    }
    return this.subtotal
  }
  // calculate grand total of cart
  calculateGrandTotal() {
    this.grandTotal = this.subtotal += this.tax

    return this.grandTotal
  }

    /**
 * Function to delete a cart item.
 */
  deleteCart() {

    if (confirm(`Are you sure you want to delete ${this.cart.cartName}? This will remove the cart permanently.`)) {
      const deleteCart = { ...this.cart, _id: undefined, version: this.helper.getTimestamp(), active: false };

      this.store.dispatch(CatalogActions.addCart({
        cart: deleteCart
      }));

    }
    this.router.navigate(['catalog/'])
  }

  deleteDesign(cart, design, product) {
    if (confirm(`Are you sure you want to delete "${design?.designName}: ${product?.brand} ${product?.style} ${product?.title} - ${product.variants?.[0]?.color}"? This will remove the product from the cart permanently.`)) {

      const updatedCart = { ...cart, _id: undefined, version: this.helper.getTimestamp() }

    // Remove the design with matching _id from the cart
    if (updatedCart.designs && updatedCart.designs.length > 0) {
      updatedCart.designs = updatedCart.designs.filter((d) => d._id !== design._id);
    }

      this.store.dispatch(CatalogActions.addCart({
        cart: updatedCart
      }));
    }
  }

  deleteProduct(cart, design, product) {
    if (confirm(`Are you sure you want to delete "${design.designName}: ${product.brand} ${product.style} ${product.title} - ${product.variants[0].color}"? This will remove the product from the cart permanently.`)) {

      const updatedCart = { ...cart, _id: undefined, version: this.helper.getTimestamp() }

      // Modify the design.products array to remove the product with matching _id
      if (updatedCart.designs && updatedCart.designs.length > 0) {
        updatedCart.designs = updatedCart.designs.map((d) => {
          if (d._id === design._id) {
            // If the design matches, update its products array by removing the product with matching _id
            d = { ...d, products: d.products.filter((p) => p._id !== product._id) };
          }
          return d;
        });
      }
      this.store.dispatch(CatalogActions.addCart({
        cart: updatedCart
      }));
    }
  }

  // Function that will allow the user to edit the selected product in the Design tool
  editDesign(design) {
    if(this.cartId != '') {
      const queryParams = design ? { queryParams: {color: design?.products?.variants[0]?.color, design: design?._id, cart: this.cartId} } : {}
  
      this.router.navigate([`catalog/${design?.products?.brand}/${design?.products?.style}/customize`], queryParams)
    }
  }

  //UI Helper function
  scrollRight(container) {
    this.animateScroll(container, 325);
  }

  scrollLeft(container) {
    this.animateScroll(container, -325);
  }

  animateScroll(container, distance) {
    const start = container.scrollLeft;
    const duration = 500; // in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = this.easeInOutQuad(progress);
      container.scrollLeft = start + (distance * easedProgress);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }

  easeInOutQuad(progress) {
    progress /= 0.5;
    if (progress < 1) {
      return 0.5 * progress * progress;
    }
    progress--;
    return -0.5 * (progress * (progress - 2) - 1);
  }

  proceedToCheckout() {
    // Call the DataLayerService method
    this.dataLayerService.logBeginCheckout(this.cart);
    
    // Navigate to the checkout page
    this.router.navigate(['/cart', this.cartId, 'checkout']);
  }
  ngOnDestroy() {
    this.destroy$.next(true)
  }
}
