import { createAction, props } from "@ngrx/store";
import { Client } from "../../models/client";
import { CanvasDimensions, DecorationTypeEnum, DesignToolPosition, Designs, PositionDesign } from "src/app/core/models/designTool";
import { CatalogProductDetails } from "@core/models/products";
import { MockupsUploadResponse } from "@core/models/catalog";
import { FileUploadJob } from "@features/catalog/product-design/upload-art-modal/upload-art-modal.component";
import { User } from "@auth0/auth0-angular";

// Design Library Actions

// Upload files to AWS S3 Bucket
export const uploadArtFile = createAction(
    '[Design Tool] Upload Art File',
    props<{ fileUploadJob: FileUploadJob }>()
)

export const uploadArtFileSuccess = createAction(
    '[Design Tool] Upload Art File Success',
    props<{ uploadArtFileResponse: any, fileUploadJob: FileUploadJob }>()
)

export const uploadArtFileFailure = createAction(
    '[Design Tool] Upload Art File Failure',
    props<{ error: string }>()
)

// Get the client designs
export const getClientDesigns = createAction(
    '[Design Tool] Get Client Designs',
    props<{ client?: Client }>()
)

export const getClientDesignsSuccess = createAction(
    '[Design Tool] Get Client Designs Success',
    props<{ clientDesignsResponse: Designs[] }>()
)

export const getClientDesignsFailure = createAction(
    '[Design Tool] Get Client Designs Failure',
    props<{ error: string }>()
)

// Get a product details
export const getCatalogProductDetails = createAction(
  "Get A Product's Detail",
  props<{ brand: string, style: string }>()
)

export const getCatalogProductDetailsFailure = createAction(
  'Get Catalog Product Details Failure',
  props<{ error: string }>()
)

export const getCatalogProductDetailsSuccess = createAction(
  'Get Catalog Product Details Success',
  props<{ product: CatalogProductDetails }>()
)

// Get the design files from the AWS S3 Bucket
export const getDesignLibrary = createAction(
    '[Design Tool] Get Design Library',
    props<{ client?: Client }>()
)

export const removeFileFromDesignLibrary = createAction(
    '[Design Tool] Remove File From Design Library',
    props<{ fileName?: string }>()
)

export const removeFileFromDesignLibrarySuccess = createAction(
    '[Design Tool] Success On Remove File From Design Library',
    props<{ resp?: boolean }>()
)

export const getDesignLibrarySuccess = createAction(
    '[Design Tool] Get Design Library Success',
    props<{ designLibraryResponse: any }>()
)

export const getDesignLibraryFailure = createAction(
    '[Design Tool] Get Design Library Failure',
    props<{ error: string }>()
)

// Get the canvas dimensions
export const getCanvasDimensions = createAction(
    '[Design Tool] Get Canvas Dimensions',
    props<{ brand: string, style: string, color: string }>()
)

export const getCanvasDimensionsSuccess = createAction(
    '[Design Tool] Get Canvas Dimensions Success',
    props<{ canvasDimensions: CanvasDimensions }>()
)

export const getCanvasDimensionsFailure = createAction(
    '[Design Tool] Get Canvas Dimensions Failure',
    props<{ error: string }>()
)

// Set the search term used to filter the design library
export const setSearchTerm = createAction(
    '[Design Tool] Set Search Term',
    props<{ searchTerm: string }>()
)

// Set the image design
export const setImageDesign = createAction(
    '[Design Tool] Set Image Design',
    props<{ imageDesign: string }>()
)

// Set the position design
export const setPositionDesign = createAction(
    '[Design Tool] Set Position Design',
    props<{ positionDesign: [] }>()
)

// Set the active position
export const setActivePosition = createAction(
    '[Design Tool] Set Active Position',
    props<{ activePosition: string }>()
)


// set the design object to be used in the design tool
export const setDesignObject = createAction(
    '[Design Tool] Set Design Object',
    props<{ designObject: Designs }>()
)

// set the design object to be used in the design tool
export const setCatalogCurrentProduct = createAction(
  '[Design Tool] Set The current product data to be used in design tool',
  props<{ product: CatalogProductDetails }>()
)

// update the design position
export const updateDesignPositionImage = createAction(
    '[Design Tool] Update Design Position Image',
    props<{ position: string, design: PositionDesign }>()
)

// update the position data for the image
export const updateDesinPositionObject = createAction(
    '[Design Tool] Update Design Position Object',
    props<{ position: string, design: PositionDesign[], decorationType?: DecorationTypeEnum }>()
)

export const updateDesignPositions = createAction(
    '[Design Tool] Update Design Position',
    props<{ designId: string, positions: Partial<DesignToolPosition>[] }>()
)

export const updateDesignPositionSuccess = createAction(
    '[Design Tool] Update Design Position Success',
    props<{ response: any }>()
)

export const updateDesignPositionFailure = createAction(
    '[Design Tool] Update Design Position Failure',
    props<{ error: string }>()
)

// updated the position data (image url of the canvas) for the image
export const updateDesignPositionCanvasImage = createAction(
    '[Design Tool] Update Design Position Canvas Image',
    props<{ updates: Array<MockupsUploadResponse> }>()
)

// save changes in a design position
export const saveDesignPositionCanvasChangesSuccess = createAction(
  '[Design Tool] Save changes in canvas',
  props<{ position: string, design: PositionDesign[], decorationType?: string }>()
)

// delete a position data for active img
export const deleteDesignPositionObject = createAction(
    '[Design Tool] Delete Design Position Object',
    props<{ position: string, imageUrl: string, design?: PositionDesign[], decorationType?: string }>()
)

export const updateDesignObjectEditable = createAction(
    '[Design Tool] Update Design Object Editable',
    props<{ position: string, imageUrl: string }>()
)

// update the design name in design object
export const updateDesignNameObject = createAction(
    '[Design Tool] Update Design Name Object',
    props<{ name: string }>()
)

// update the design product variants in design object
export const updateDesignProductVariantsObject = createAction(
    '[Design Tool] Update Design Product Variants Object',
    props<{ variants: any }>()
)

// add design to mongodb designs collection
export const addDesign = createAction(
    '[Design Tool] Add Design',
    props<{ design: any, user: User }>()
)
export const addDesignSuccess = createAction(
    '[Catalog] Add Design Success',
    props<{ designResponse: any }>()
)

export const addDesignFailure = createAction(
    '[Catalog] Add Design Failure',
    props<{ error: string }>()
)

// Get the Mockups used for the design tool
export const getDesignToolMockups = createAction(
    '[Design Tool] Get Mockup Images',
    props<{ product: any }>()
)

export const getDesignToolMockupsSuccess = createAction(
    '[Design Tool] Get Mockup Images Success',
    props<{ mockupImagesResponse: any }>()
)

export const getDesignToolMockupsFailure = createAction(
    '[Design Tool] Get Mockup Images Failure',
    props<{ error: string }>()
)


export const updateCurrentPositionData = createAction(
    '[Design Tool] Update Current Position Data',
    props<{ position: any }>()
)

export const getDesignToolCanvas = createAction(
    '[Design Tool] Get Design Tool Canvas',
    props<{ data: any }>()
)

export const getDesignToolCanvasSuccess = createAction(
    '[Design Tool] Get Design Tool Canvas Success',
    props<{ designToolCanvasResponse: any }>()
)

export const getDesignToolCanvasFailure = createAction(
    '[Design Tool] Get Design Tool Canvas Failure',
    props<{ error: string }>()
)

export const getDesignToolCanvasAvailable = createAction(
    '[Design Tool] Get Design Tool Canvas Available'
)
export const getDesignToolCanvasAvailableSuccess = createAction(
    '[Design Tool] Get Design Tool Canvas Available Success',
    props<{ designToolCanvasAvailable: any }>()
)
export const getDesignToolCanvasNotAvailableFailure = createAction(
    '[Design Tool] Get Design Tool Canvas Available Failure',
    props<{ error: string }>()
)
export const setMobileView = createAction(
    "[Design Tool] Set if it's mobile view",
    props<{mobileView: boolean}>()
)
export const setScaleFactor = createAction(
    "[Design Tool] Set the scale factor to be used for canvas dimensions and images inside",
    props<{scaleFactor: number}>()
)