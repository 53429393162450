import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { SessionService } from "../services/session/session.service";
import { AuthService } from "@auth0/auth0-angular";
import { environment } from "environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private sessionService: SessionService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const publicUrls = [`${environment.apiMicroservice}products/`, `${environment.apiMicroservice}aws/design/mockup/files`, `${environment.apiMicroservice}marketplace/pricing/design-tool`, `${environment.apiRoot}addresses/create_and_verify`, `${environment.apiRoot}shipments`, `${environment.apiUrl2}api/v2/order/client-portal/approvals`, `${environment.apiUrl2}api/v2/cloud/platform/art-approval/`, `${environment.apiUrl2}api/v2/cloud/platform/photo-approval/`, 'https://img-art.stokkup.com/', `${environment.apiUrl2}api/v2/feedback/`, '/api/img/', `${environment.apiRoot}client-portal/art/download-art-approval`, `${environment.apiUrl}art/approve/`, `${environment.apiUrl}art/feedback/`]
        const protectedUrls = [environment.apiRoot, environment.apiUrl, environment.apiUrl2, environment.apiMicroservice]

        if (publicUrls.some(e => request.url.includes(e))) {
            return next.handle(request)
        }

        return this.authService.user$.pipe(
            switchMap(currentUser => {
                return this.authService.getAccessTokenSilently().pipe(
                    switchMap(token => {
                        const activeClient = this.sessionService.getActiveClient();
                        const activeDecorator = this.sessionService.getActiveDecorator();
                        
                        if (currentUser) {
                            let headers = new HttpHeaders({
                                'CP-User-Id': currentUser?.cpu_id?.toString(),
                                'CP-User-Email': currentUser?.email,
                                'CP-User-Token': 'Bearer ' + token,
                                'CP-Affiliate': currentUser?.is_affiliate_user ?? 0,
                            })

                            if (protectedUrls.some(e => request.url.includes(e))) {
                                headers = headers.append('Authorization', 'Bearer ' + token)
                            }

                            if (activeClient?.id) {
                                headers = headers.append('CP-Client-Id', `${activeClient.id}`);
                            }

                            if (activeDecorator?.id) {
                                headers = headers.append('CP-Decorator-Id', `${activeDecorator.id}`);
                            }

                            request = request.clone({ headers });
                        }

                        return next.handle(request).pipe(
                            catchError((error: HttpErrorResponse) => {
                                console.error('an error happened while doing a request', error)
                                if (error.status === 401) {
                                    console.error('an authentication error occurred trying to get data, login you out')
                                    this.authService.logout({
                                        openUrl: (url) => {
                                            this.sessionService.flushUser();
                                            window.location.replace(url);
                                        },
                                        logoutParams: {
                                            returnTo: window.location.origin,
                                            federated: true
                                        }
                                    }).subscribe();
                                }
                                return throwError(() => error);
                            })
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorMsg = error.toString()
                        if (errorMsg.includes('Missing Refresh Token') || errorMsg.includes('Unknown or invalid refresh token')) {
                            console.error('an error was found trying to get a token, login you out')
                            this.authService.logout({
                                openUrl: (url) => {
                                    this.sessionService.flushUser();
                                    window.location.replace(url);
                                },
                                logoutParams: {
                                    returnTo: window.location.origin,
                                    federated: true
                                }
                            }).subscribe();
                        }
                        return throwError(() => error);
                    })
                )
            })
        );
    }
}
