import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from './pipe/ellipsis.pipe';
import { ArrayCountPipe } from './pipe/array-count.pipe';
import { ListViewPipe } from './pipe/list-view.pipe';
import { UniqueArrayParamPipe } from './pipe/unique-array-param.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from './shared.service';
import { StringToArrayPipe } from './pipe/string-to-array.pipe';
import { MdbModule } from '../core/mdb.module';
import { AlphaImagesPipe } from './pipe/alpha-images.pipe';
import { ImageStringArrayCpPipe } from './pipe/image-string-array-cp.pipe';
import { ImageStringFormatterCpPipe } from './pipe/image-string-formatter-cp.pipe';
import { RemoveNullStringPipe } from './pipe/remove-null-string.pipe';
import { OrdersTemplateTableComponent } from '../templates/orders-template/orders-template-table/orders-template-table.component';
import { ProperCasePipe } from './pipe/proper-case.pipe';
import { ImageDateFormatPipe } from './pipe/image-date-format.pipe';
import { FormatPricePipe } from './pipe/format-price.pipe';
import { FooterComponent } from '@features/catalog/footer/footer.component';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { FilterInputDataPipe } from './pipe/filter-input-data.pipe';
import { PopConfirmComponent } from './components/pop-confirm/pop-confirm.component';
import { FilterNullPipe } from './pipe/filter-null.pipe';

@NgModule({
  declarations: [ EllipsisPipe, ArrayCountPipe, ListViewPipe, UniqueArrayParamPipe, StringToArrayPipe, AlphaImagesPipe, ImageStringArrayCpPipe, ImageStringFormatterCpPipe, RemoveNullStringPipe, OrdersTemplateTableComponent, ProperCasePipe, ImageDateFormatPipe, FormatPricePipe, FooterComponent, GooglePlacesDirective, FilterInputDataPipe, PopConfirmComponent, FilterNullPipe ],
  imports: [
    CommonModule,
    MdbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ EllipsisPipe, ArrayCountPipe, ListViewPipe, UniqueArrayParamPipe, StringToArrayPipe, AlphaImagesPipe, ImageStringArrayCpPipe, ImageStringFormatterCpPipe, RemoveNullStringPipe, OrdersTemplateTableComponent, ProperCasePipe, ImageDateFormatPipe, FormatPricePipe, FooterComponent, GooglePlacesDirective, FilterInputDataPipe, FilterNullPipe ],
  providers: [SharedService]
})
export class SharedModule { }
