import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { SessionService } from '@core/services/session/session.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private session: SessionService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$)
    
    if(!isAuthenticated) {
      this.auth.loginWithRedirect({
        openUrl: (url) => {
          this.session.flushUser();
          window.location.replace(url);
        },
        appState: {
          redirect: state.url
        }
      });
    }

    return isAuthenticated
  }
}
