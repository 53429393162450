import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ErrorsService } from '@core/services/errors/errors.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {SessionService} from "../services/session/session.service";
import { Store } from '@ngrx/store';
import { Decorator } from '@core/models/decorator';

@Injectable({
  providedIn: 'root'
})
export class SelectedDecoratorGuard implements CanActivate{
  constructor(private session: SessionService, private auth: AuthService, private router: Router, private errorsService: ErrorsService, private store: Store) {}

async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await firstValueFrom(this.auth.user$)
    const activeDecorator = this.session.getActiveDecorator()
    const activeClient = this.session.getActiveClient()

    if(user?.is_affiliate_user == 0) {
      if (!activeDecorator) {
        const defaultDecorator = {
          id:100015,
          name:"Culture Studio",
          address:"1151 W 40th street ",
          city:"Chicago",
          state:"IL",
          postalCode:"60609",
          phone:"3122438304",
          email:"rich@culturestudio.net",
          avatarImg:`${environment.apiRoot}uploads/100015/staff/17/staff-logo-1590857842.png`,
          avatarTwoChar:"CS"
        } as Decorator
        this.session.setActiveDecorator(defaultDecorator)
      }
      if (!activeClient && !state.url.includes('/login/choose-client')) {
        const incomingQueryParams = new URLSearchParams(window.location.search);
        const outgoingQueryParams: any = {};

        if (state.url.length > 0) {
          if (incomingQueryParams.has('redirect')) {
            outgoingQueryParams.redirect = incomingQueryParams.get('redirect')
          } else {
            outgoingQueryParams.redirect = state.url
          }
        }
        this.router.navigate(['/login/choose-client'], {queryParams: outgoingQueryParams});
      }
    } else if (user) {
      this.errorsService.setErrorState({
        imageUrl: 'assets/images/oops-t-shirt.png',
        title: 'You are not allowed to access',
        message: `This platform is not designed for your current user type`,
        buttons: [
          {
            text: 'Access with a different account',
            callback: () => {
              this.auth.logout({
                openUrl: (url) => {
                  this.session.flushUser();
                  window.location.replace(url);
                },
                logoutParams: {
                  returnTo: environment.baseUrl,
                  federated: true
                }
              })
            }
          }
        ],
      })
      this.errorsService.showError()
    }

    return true
  }
}
