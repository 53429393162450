import { createAction, props } from "@ngrx/store"
import { Client, ICreateClientContact } from "../../models/client"

// Get Client Contacts
export const getClientContacts = createAction(
    '[Client] Get Client Contacts',
    props<{clientId: number}>()
)
export const getClientContactsSuccess = createAction(
    '[Client] Get Client Contacts Success',
    props<{ clientContacts: any }>()
)
export const getClientContactsFailure = createAction(
    '[Client] Get Client Contacts Failure',
    props<{ error: string }>()
)

// Create Client Contact
export const createClientContact = createAction(
    '[Client] Create Client Contact',
    props<{clientContact: ICreateClientContact}>()
)

export const createClientContactSuccess = createAction(
    '[Client] Create Client Contact Success',
    props<{ clientContact: Client }>()
)
export const createClientContactFailure = createAction(
    '[Client] Create Client Contact Failure',
    props<{ error: string }>()
)

// Get Avalara Tax
export const getAvalaraClient = createAction(
    '[Client] Get Avalara Client',
    props<{customerCode?: number}>()
)

export const getAvalaraClientSuccess = createAction(
    '[Client] Get Avalara Client Success',
    props<{ avalaraClient: any }>()
)

export const getAvalaraClientFailure = createAction(
    '[Client] Get Avalara Client Failure',
    props<{ error: string }>()
)