import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCatalog from '@core/state/catalog/catalog.reducers';

export const selectCatalogState = createFeatureSelector<fromCatalog.State>('catalog');

export const selectCatalogHero = createSelector(selectCatalogState, state => state.contentfulHero); // get hero to display in catalog
export const selectCatalogCategories = createSelector(selectCatalogState, state => state.contentfulCategories); // get categories to display in catalog
export const selectCatalogContentfulDesignToolProducts = createSelector(selectCatalogState, state => state.contentfulDesignToolProducts); // get contentful design tool products to display in catalog
export const selectCatalogContentfulProducts = createSelector(selectCatalogState, state => state.contentfulProducts); // get contentful products to display in catalog
export const selectCatalogFeaturedCollections = createSelector(selectCatalogState, state => state.contentfulFeaturedCollections); // get featured collections to display in catalog
export const selectCatalogContentfulBanner = createSelector(selectCatalogState, state => state.contentfulBanner); // get banner to display in catalog
export const selectCatalogBrands = createSelector(selectCatalogState, state => state.contentfulBrands); // get brands to display in catalog
export const selectCatalogSubcategories = createSelector(selectCatalogState, state => state.contentfulSubcategories); // get subcategories to display in catalog
export const selectCatalogProducts = createSelector(selectCatalogState, state => state.products); // get products to display in catalog
export const selectCatalogTotalProductsCount = createSelector(selectCatalogState, state => state.totalProductsCount); // get products to display in catalog
export const selectCatalogTotalPages = createSelector(selectCatalogState, state => state.totalPages); // get products to display in catalog
export const selectCatalogFilters = createSelector(selectCatalogState, state => state.filters.productFiltersResponse); // get filter response from API to display in catalog
export const selectCatalogQuery = createSelector(selectCatalogState, state => state.filters.productSelectedFilters); // get current query filters in catalog (used for filter chips)
export const selectBrandsNav = createSelector(selectCatalogState, state => state.filters.productBrands); // get nav Brands
export const selectCategoriesNav = createSelector(selectCatalogState, state => state.filters.productCategories); // get nav categories
export const selectProductVariant = createSelector( selectCatalogState, state => state.productVariant); // get the product variant to display in catalog
export const selectProductVariantColor = createSelector( selectCatalogState, state => state.productVariantColor); // get data for selected color in Design tool
export const selectPricingProductVariantColor = createSelector( selectCatalogState, state => state.productVariantColorPricing); // get data for selected color in Design tool
export const selectCarts = createSelector( selectCatalogState, state => state.carts); // get carts by clientId
export const selectSearchTerm = createSelector( selectCatalogState, state => state.searchTerm); // get search term from search bar

export const selectHero = (heroId) =>  createSelector(selectCatalogHero, (heroes) => {
    let result;
    if(heroes){
        heroes.forEach(hero => {
            if(hero.heroCategoryName == heroId) {
                result = hero;
            }
        })
    }
    return result;
});

export const selectProductsSectionName = () =>  createSelector(selectCatalogContentfulProducts, (products) => {
    let result;
    if(products){
        products.forEach(product => {
            if(Boolean(product.sectionName)) {
                result = product.sectionName;
            }
        })
        if(!Boolean(result)) {
            result = "Featured Products"
        }
    }
    return result;
});

export const selectDesignSectionName = () =>  createSelector(selectCatalogContentfulDesignToolProducts, (products) => {
    let result;
    if(products){
        products.forEach(product => {
            if(Boolean(product.sectionName)) {
                if(!result) {
                    result = product.sectionName;
                }
            }
        })
        if(!Boolean(result)) {
            result = "Customize Your Own Products"
        }
    }
    return result;
});

export const selectSubcategory = (subCategoryId) =>  createSelector(selectCatalogSubcategories, (subcategories) => {
    let result;
    if(subcategories){
        subcategories.forEach(subcategory => {
            if(subcategory.mainCategory == subCategoryId) {
                result = subcategory;
            }
        })
    }
    return result;
});

export const selectProducts = createSelector(
    selectCatalogProducts,
    (products) => products || [] // Return empty array if products is falsy
);

export const selectAscProducts = createSelector(
    selectCatalogProducts,
    (products) => {
      if (!products || !Array.isArray(products)) {
        return [];
      }
  
      // Sort the products array alphabetically by product.brand
      return products.slice().sort((a, b) => a.brand.localeCompare(b.brand));
    }
  );

  export const selectDescProducts = createSelector(
    selectCatalogProducts,
    (products) => {
      if (!products || !Array.isArray(products)) {
        return [];
      }
  
      // Sort the products array in descending order alphabetically by product.brand
      return products.slice().sort((a, b) => b.brand.localeCompare(a.brand));
    }
  );

export const selectPrimaryQueryHeader = () =>  createSelector(selectCatalogQuery, (filters) => {
    let result;
    if (filters && Array.isArray(filters) && filters.length > 0) {
        const firstFilter = filters[0];
        result = !!firstFilter && typeof firstFilter == 'object' ? Object.values(firstFilter)[0] : null;
    }
    return result;
});

export const selectCartById = (cartId: string) =>
  createSelector(
    selectCarts,
    (carts) => {
        const foundCart = carts.find((cart) => cart.cartId == cartId);
        return foundCart || {}; // Return the found cart or an empty object if not found
    }
  );

export const selectVariantQtysFromCartById = (cartId: string, designId: string) =>
  createSelector(
    selectCarts,
    (carts: any) => {
      const foundCart = carts.find((cart) => cart.cartId === cartId);

      if (foundCart && foundCart.designs) {
        return foundCart.designs
          .filter((design) => design._id === designId)
          .flatMap((design) =>
            design?.products?.variants ? design.products.variants.map((variant) => variant.qty) : []
          );
      }
      return [];
    },
  );


export const selectContentfulBannerByName = (title: string) =>  createSelector(
  selectCatalogContentfulBanner, 
  (banners) => banners.find(banner => banner.image.fields.title == title)
);