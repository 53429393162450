import { createReducer, on } from "@ngrx/store";
import { Client } from "../../models/client";
import { Decorator } from "../../models/decorator";
import { getAllUserClientsSuccess, getAllUserClientsFailure, getAllUserDecoratorsFailure, getAllUserDecoratorsSuccess, getAllAffiliateDecoratorsSuccess, getAllAffiliateDecoratorsFailure } from "./user.actions";
import { User } from "@auth0/auth0-angular";

export const featureName = "user";

export interface State {
    decorators?: [Decorator],
    clients?:[Client],
    userError?: string,
    decoratorError?:string,
    clientError?:string,
    clientContextError?: string
}

export const initialState: State = {
    decorators: null,
    clients: null,
}

const _userReducer = createReducer(
    initialState,
    on(getAllUserClientsSuccess, (state, {userClients}) => {
        return {
            ...state,
            clients: userClients.data
        }
    }),
    on(getAllUserClientsFailure, (state, {error}) => {
        console.error(error)
        return {
            ...state,
            clients: null,
            clientError: error
        }
    }),
    on(getAllAffiliateDecoratorsSuccess, (state, {affiliateDecorators}) => {
        return {
            ...state,
            decorators: affiliateDecorators.data
        }
    }),
    on(getAllAffiliateDecoratorsFailure, (state, {error}) => {
        return {
            ...state,
            decorators: null,
            decoratorError: error
        }
    }),
    on(getAllUserDecoratorsSuccess, (state, {userDecorators}) => {
        return {
            ...state,
            decorators: userDecorators.data
        }
    }),
    on(getAllUserDecoratorsFailure, (state, {error}) => {
        return {
            ...state,
            decorators: null,
            decoratorError: error
        }
    })
)

export function userReducer(state, action){
    return _userReducer(state, action)
}
