import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import { SessionService } from '@core/services/session/session.service';
import { getAllUserClients } from '@core/state';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseNavResolver  {

  constructor(
    private store: Store,
    protected session: SessionService,
    private auth0Service: AuthService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const clientId = this.session.getActiveClient()?.id;
    const isAuthenticated = await firstValueFrom(this.auth0Service.isAuthenticated$);
    
    if (isAuthenticated) {
      if (clientId) {
        this.store.dispatch(CatalogActions.getCarts({ clientId }));
      }
      this.store.dispatch(getAllUserClients({decoratorId: 100015}))
    }
    return true;
  }
}
