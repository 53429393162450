import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorsService } from 'src/app/core/services/errors/errors.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  constructor(public errorsService: ErrorsService, private router: Router) {}

  async onButtonClick(routerLink: string, routerParams?: Object, callback?: () => void) {
    if (routerLink?.length > 0) {
      this.router.navigate([routerLink], {queryParams: routerParams})
    }
    callback?.()

    setTimeout(() => {
      this.errorsService.hideError()
      this.errorsService.setErrorState(undefined)
    }, 500)
  }
}
