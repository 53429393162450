import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CartBaseComponent } from "./cart-base/cart-base.component";
import { CartCheckoutComponent } from "./cart-checkout/cart-checkout.component";

const routes: Routes = [
    { path: '', component: CartBaseComponent },
    { path: ':id', component: CartBaseComponent },
    { path: ':id/checkout', component: CartCheckoutComponent },
    { path: 'checkout', component: CartCheckoutComponent },
    { path: 'confirmation/:orderId', component: CartCheckoutComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CartRoutingModule { }