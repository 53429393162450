<section class="about-card card">
  <h1>Terms of Service</h1>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup.com
      Terms of Use</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Updated
      Feb 14, 2022</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These
      Terms of Use (the &quot;Agreement&quot;) are between you and Stokkup (&quot;Stokkup,&quot; &quot;we,&quot;
      &quot;us,&quot; and &quot;our&quot;). This Agreement sets forth the terms and conditions upon which we make</span>
  </p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">available
      www.culturestudio.net and its contents and offerings to you (the &ldquo;Site&rdquo;).</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PLEASE
      NOTE: The Site is intended for use by residents of the United States and Canada, excluding the province of Quebec,
      who are at least 18 years of age. Read carefully below before using the Site. Note also that our use of your
      personal information is governed at all times by our Privacy Statement which is available at www.culturestudio.net. See
      below for requirements relating to how disputes between you and Stokkup are resolved.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Modification
      to Terms of Use</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Amendments
      to this Agreement will be posted on the Site. To the maximum extent allowed by law and except as otherwise
      expressly provided at the time of such amendment, such amendments are effective when posted. Your continued use of
      the Site following the posting of any amendment shall constitute your acceptance thereof. If you do not agree to
      the terms of this Agreement or any amendment, do not use the Site.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Using
      the Site</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Subject
      to compliance with the Agreement, including but not limited to applicable age and residency restrictions, you have
      a limited, non-transferable, non-sublicensable and revocable license to access and use the Site for its intended
      purpose which shall be for your personal use and not, for example, for reselling or outsourcing the Site&rsquo;s
      functionality or otherwise using it as a service bureau or data center. You may print copies of Site materials as
      reasonably necessary for permitted use of the Site, but such copies shall not be distributed to others. Uses of
      the Site not expressly authorized herein are prohibited.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      agree to ascertain and obey all applicable local, state, federal, and international laws (including minimum age
      requirements) in regard to the design, ordering, receipt, possession, use, and sale of any product obtained
      through the Site, including but not limited to copyright, and other intellectual property laws. Subject to
      applicable law, Stokkup reserves the right, with or without prior notice, to do any one or more of the following:
      (a) limit the available quantity of, or discontinue any offering of, any product or promotion; (b) impose
      conditions on your use of the Site; and (c) bar you from making or completing any or all transaction(s). You agree
      to pay all charges that may be incurred by you or on your behalf through the Site, at the price(s) in effect when
      such charges are incurred including, without limitation, all shipping and handling charges. In addition, you
      remain responsible for any taxes that may be applicable to your purchase transactions.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Account
      Registration, Password and Security</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Some
      portions of the Site require you to register and you may be asked to select a password. You agree to provide
      accurate and truthful information and to update such information to keep it true and accurate. Select a password
      that would be difficult to guess, and change it regularly as an added precaution. You agree not to share your
      password or share access to your account. You agree that you are responsible for activities that occur under your
      account. You agree to notify us immediately of unauthorized use of your password or account.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      reserve the right to take such action in our discretion to help ensure the security of the Site, including,
      without limitation, terminating an account. Notwithstanding the above, we may rely on the authority of anyone
      accessing your account or using your password, and in no event, and under no circumstances, shall we be held
      liable to you for any liabilities or damages arising out of (i) any action or inaction of Stokkup under this
      provision or (ii) any compromise of the confidentiality of your account or password or any unauthorized access to
      your account or use of your password except to the extent such compromise, access or use is caused solely by
      Stokkup&rsquo;S breach of thE EXPRESS TERMS OF THIS agreement, as proved by clear and convincing evidence. You may
      not use anyone else&rsquo;s account at any time.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Purchase
      Transactions</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All
      descriptions, images, references, features, content, specifications, products, and prices of products and services
      described or depicted on the Site are subject to change at any time without notice.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Revenue
      Recognition</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
  </p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For
      performance obligations related to the sale of merchandise, control transfers to the customer at a point in time.
      &nbsp;The Company transfers control and records revenue for product sales once the entire purchase order has been
      fulfilled and the items have been boxed, sealed and labeled. If, for any reason, the shipment is requested to be
      held on the dock, storage fees will be issued and billed separately.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Finance
      Charges</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Company
      reserves the right to charge 1.5% per month or 18% annually on all past due accounts. The customer shall be
      responsible for all costs of collection incurred by Company, including without limitation lien costs and all
      attorneys&apos; fees and expenses.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Prices
      and Payment Terms</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Unless
      otherwise noted, all prices are subject to change without notice. The Company is not responsible for typographical
      errors. Payment terms are Net 30 days unless expressly stated differently on the invoice or quotation.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Prohibited
      Conduct</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      agree not to: (a) access or attempt to access any information, documents, images, software or material
      (individually and collectively, &ldquo;Materials&rdquo;) that you are not authorized to access and/or through any
      means that you are not authorized to use such as any means not intentionally made available through the Site; (b)
      disrupt or interfere with the security of, or otherwise cause harm to the Site, or to any Materials, systems
      resources, accounts, passwords, servers or networks connected to or accessible through the Site or any affiliated
      or linked websites; (c) access or use the Site in any manner that could damage, disable, overburden or impair any
      server or network used by Stokkup in connection with the Site; (d) use any Materials in connection with the Site
      in any manner that infringes upon any intellectual property rights or other rights of any party; (e) transmit
      unsolicited or bulk communications to any Stokkup account holder or to any www.culturestudio.net email address or other
      email addresses associated with Stokkup; (f) post or otherwise submit any software, programs or Materials via the
      Site that are harmful or disruptive of another&rsquo;s equipment, software or other property, including any
      corrupted files, time bombs, Trojan Horses, viruses and worms; (g) disrupt, interfere with or inhibit any other
      user from using and enjoying the Site or other websites, Materials or services associated with Stokkup; (h)
      violate any applicable laws or regulations related to the access to or use of the Site, and/or engage in any
      activity prohibited by this Agreement; (i) compile, use, download or otherwise copy any Materials available on the
      Site (except as expressly permitted by the Agreement), or transmit, provide or otherwise distribute (whether or
      not for a fee) such Materials to any third party; (j) use the Site to engage in any chain letters, contests, junk
      email, pyramid schemes, spamming, surveys or any other duplicative or unsolicited messages (commercial or
      otherwise); (k) use any robot, spider, or other programmatic or automatic device, to obtain information from the
      Site or otherwise monitor or copy any portion of the Site; (l) frame, mirror, or use framing techniques on any
      part of the Site without Stokkup&rsquo;s express prior written consent; (m) make any use of data extraction,
      scraping, mining, or other data gathering tools, or create a database by systematically downloading or storing
      Site Materials, or otherwise scrape, collect, store, or, except pursuant to the limited license granted by this
      Agreement, use any Materials; (n) modify, adapt, translate, reverse engineer, decompile, or disassemble any
      portion of the Site; (o) remove any copyright, trademark, or other proprietary rights notice from the Site or
      Materials; and/or (p) use any Stokkup domain name as a pseudonymous return email address. These examples of
      prohibited conduct are illustrative and are not exhaustive. Stokkup reserves the right to take action, up to and
      including terminating a user&rsquo;s account, if the user is determined, in Stokkup&rsquo;s sole discretion, to
      have engaged in prohibited conduct or otherwise violated this Agreement.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Limitation
      of Liability</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TO
      THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, STOKKUP, ITS AFFILIATES,</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EMPLOYEES,
      DIRECTORS, OFFICERS, MEMBERS, MANAGERS, AGENTS, REPRESENTATIVES, SERVICE PROVIDERS, SUPPLIERS, AND MERCHANTS
      (STOKKUP AND SUCH OTHER INDIVIDUALS AND ENTITIES INDIVIDUALLY AND COLLECTIVELY REFERRED TO AS
      &ldquo;STOKKUP-RELATED PARTIES&rdquo;) SHALL NOT BE RESPONSIBLE FOR, NOR BE LIABLE TO YOU OR ANY THIRD PARTY FOR,
      ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OR LOST PROFITS ARISING OUT OF OR
      RELATING IN ANY WAY TO THE SITE, OR INFORMATION CONTAINED WITHIN THE SITE, INCLUDING, BUT NOT LIMITED TO, YOUR
      CONDUCTING OF ANY TRANSACTIONS BY OR THROUGH THE SITE. ADDITIONALLY, TO THE MAXIMUM EXTENT AUTHORIZED BY LAW, THE
      TOTAL AGGREGATE AND COMBINED LIABILITY OF THE STOKKUP-RELATED PARTIES SHALL BE LIMITED TO THE AMOUNT OF MONEY, IF
      ANY, THAT YOU EXPENDED IN CONNECTION WITH THE TRANSACTION OR EVENT GIVING RISE TO THE CLAIM. THE FOREGOING
      LIMITATIONS APPLY IRRESPECTIVE OF WHETHER A CLAIM IS BROUGHT UNDER CONTRACT, TORT, WARRANTY OR OTHER THEORY.
      APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF LIABILITY SET FORTH ABOVE, SO THIS LIMITATION OF LIABILITY MAY NOT
      APPLY TO YOU, AND YOU MAY HAVE RIGHTS IN ADDITION TO THOSE CONTAINED HEREIN. IN SUCH JURISDICTIONS,
      STOKKUP-RELATED PARTIES&apos; LIABILITY IS LIMITED TO THE MAXIMUM EXTENT ALLOWED BY LAW.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Disclaimer:
      Stokkup makes no, and hereby expressly disclaims all warranties, express or implied, with respect to the nature,
      quantity or quality of the services to be performed hereunder. Except to the extent of its gross negligence or
      willful misconduct, Stokkup shall not be liable to the customer for any damages as a result of its performance or
      failure to perform the services.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Contract
      Claims Only. Customer&apos;s claims with respect to the services furnished hereunder shall be limited to the
      contractual warranties and remedies provided in these Terms and Conditions and may be brought only in an action
      for breach of contract. Customer shall not make any claim against Stokkup based on any theory of tort, including
      but not limited to strict liability or negligence theories, except to the extent of the gross negligence or
      willful misconduct of Stokkup.</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">WAIVER.
      CUSTOMER HEREBY WAIVES AND RELEASES STOKKUP OF ANY AND ALL LIABILITY STEMMING FROM DAMAGES TO CUSTOMER&rsquo;S
      GOODS DURING PRINTING SERVICES RENDERED BY STOKKUP. CUSTOMER EXPRESSLY ACKNOWLEDGES THAT PRINTING SERVICES MAY
      RESULT IN DAMAGES BASED UPON THE NATURE OF THE GOODS AND/OR THEIR DESIGN AND/OR MANUFACTURE.&rdquo;</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Disclaimer
      of Warranties</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      agree and acknowledge that use of the site is without warranty of any kind and that the access to and use of the
      site, as provided under the terms of use, is provided &ldquo;AS IS&rdquo; and &ldquo;as available&rdquo; basis, to
      the full extent permissible by applicable law. All warranties are hereby disclaimed, including but not limited to,
      implied warranties of non-infringement, merchantability, fitness for a particular purpose, and warranties based on
      custom or practice. Applicable law may not allow the above exclusion of IMPLIED warranties, so the exclusion may
      not apply to you and shall apply only to the maximum extent allowed by law. No advice or information, whether oral
      or written, obtained by any user from Stokkup or the site shall create any warranty not expressly stated herein.
      for the avoidance of doubt, and without limiting the foregoing, Stokkup cannot and does not represent or warrant
      that the Site, its server, or user content will be error-free, uninterrupted, timely, secure, free from viruses
      and other harmful components, free from unauthorized access (including third-party hackers or denial of service
      attacks), or otherwise meet your requirements.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Additional
      Disclaimer Related to Third-Party Content and Links to Third-Party Sites .</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      may display on the Site user content, information, instructions, videos, services, products, &nbsp;and other
      materials from third parties, and links to third-party sites. The appearance of external hyperlinks generated by
      third parties does not constitute endorsement by Stokkup or its affiliates of any content of the linked
      third-party website, and Stokkup does not verify or take responsibility for the accuracy, currency, completeness,
      or quality of the content contained on these sites. Therefore, neither Stokkup nor its affiliates will be
      responsible for any errors or omissions or for the results obtained from the use of such information contained on
      these sites. IN NO EVENT WILL WE BE LIABLE, DIRECTLY OR INDIRECTLY, TO ANYONE FOR ANY DAMAGE OR LOSS ARISING FROM
      OR RELATING TO ANY USE, CONTINUED USE OR RELIANCE ON ANY THIRD-PARTY CONTENT DISPLAYED ON THE SITE, ANY PRODUCTS,
      SERVICES OR OTHER MATERIALS RELATING TO ANY SUCH CONTENT, ANY LINKED THIRD-PARTY SITE, OR ANY LINK CONTAINED IN A
      LINKED SITE.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Indemnity</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      agree to defend, indemnify and hold harmless Stokkup, its employees, directors, officers, members, managers,
      representatives, agents, suppliers or service providers from any loss, liability, claim or demand, including
      reasonable attorneys&rsquo; fees, made by any third party due to or arising out of your use of the Site (but
      excluding claims resulting from our breach of this Agreement), including without limitation, any information or
      content you submit to the Site, any material you download from the Site or any interference with the operation of
      the Site.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Submissions</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By
      submitting any comment, idea, suggestion, response to questionnaires or other material to the Site, you grant us a
      perpetual, non-exclusive, fully-paid, royalty-free, irrevocable, sublicensable, worldwide license and right to
      display, use, perform, reproduce, modify, distribute and create derivative works of such material or information
      submitted in any media, software, or technology of any kind now existing or developed in the future. By providing
      any such material or information, or by submitting designs, drawings, or other copyrightable subject matter to the
      Site, you represent and warrant that copying, public posting and use of such material or information by Stokkup
      will not infringe on or violate the rights of any third party.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Trademarks
      and Copyrights</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup,
      the Stokkup logo, and other marks, logos, graphics, and trade dress used on the Site are Stokkup&rsquo;s
      trademarks or the trademarks of third-party affiliates, and may not be used in any manner (including in
      &ldquo;meta-tags&rdquo; or &ldquo;hidden text&rdquo;) without our prior written approval. All content included on
      the Site, such as text, graphics, images, audio clips, video, data, music, software, and other Materials, is owned
      or licensed property of Stokkup or its suppliers, merchants, or licensors and is protected by copyright or other
      proprietary rights. Any unauthorized use of these Materials may violate trademark, copyright, patent, or other
      laws. You agree not to modify, reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or
      circulate any such Materials except as expressly permitted in the Agreement.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Notices
      and Electronic Communications</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Except
      as explicitly stated otherwise, any notices you send to Stokkup shall be sent by email and regular mail at the
      addresses listed in the paragraph immediately below. In the case of notices Stokkup sends to you, you consent to
      receive notices and other communications by Stokkup posting notices on the Site or sending you an email at the
      email address listed in your profile in your account. You agree that all agreements, notices, disclosures, and
      other communications that Stokkup provides to you in accordance with the prior sentence satisfy any legal
      requirement that such communications be in writing. You agree that a printed version of this Agreement and/or any
      notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or
      relating to this Agreement to the same extent and subject to the same conditions as other business documents and
      records originally generated and maintained in printed form.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Notice</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Please
      note the following: (a) Stokkup is located at 1151 W. 40th, Chicago, Illinois 60609; (b) the fees charged for
      Stokkup products and/or services vary depending on the services selected by you; and (c) if you have a question or
      complaint regarding this Site or Stokkup&rsquo;s products or services, please send an email to tech@culturestudio.net.
      You may also contact us by writing to the address above, or by calling us at 312-243-8304. In accordance with
      California Civil Code 1789.3, California residents may reach the Complaint Assistance Unit of the Division of
      Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento,
      California 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Mandatory
      Mediation</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Customer
      agrees that he or she is required to attempt to resolve any dispute, claim or controversy arising out of or
      relating to these Terms and Conditions by mediation, which shall be conducted under the then current mediation
      procedures of American Arbitration Association in accordance with its Commercial Arbitration Rules and Mediation
      Procedures in effect on the date of these Terms and Conditions or any other procedure upon which the parties may
      agree. &nbsp;Customer further agrees that his or her good faith participation in mediation is a condition
      precedent to pursuing any other available legal or equitable remedy, including litigation, arbitration or other
      dispute resolution procedures.&nbsp;</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Except
      as otherwise provided herein, either party may commence the mediation process by providing to the other party
      written notice, setting forth the subject of the dispute, claim or controversy and the relief requested.
      &nbsp;Within ten (10) days after the receipt of the foregoing notice, the other party shall deliver a written
      response to the initiating party&apos;s notice. &nbsp;The mediation shall be conducted by any agreeable mediator
      within Chicago, Illinois. The initial mediation session shall be held within thirty (30) days after the initial
      notice. &nbsp;The parties agree to share equally the costs and expenses of the mediation (which shall not include
      the expenses incurred by each party for its own legal representation in connection with the mediation). The
      provisions of this section may be enforced by any Court of competent jurisdiction, and the party seeking
      enforcement shall be entitled to an award of all costs, fees and expenses, including reasonable attorneys&rsquo;
      fees, to be paid by the party against whom enforcement is ordered. &nbsp;If the parties are unable to reach an
      agreement during mediation conducted in good-faith, they shall be free to pursue whatever remedies they are
      entitled to at law or in equity after the expiration of such sixty (60) day mediation period.&rdquo;</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Force
      Majeure</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stokkup
      shall be excused from performance hereunder to the extent that performance is prevented, delayed, or obstructed by
      causes beyond our reasonable control such as strikes, riots, insurrection, fires, floods, explosions, war,
      governmental action, labor conditions, earthquakes, or natural disasters.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Assignment</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You
      may not assign any of your rights or obligations hereunder, whether by operation of law or otherwise, without the
      prior written consent of Stokkup. Notwithstanding the foregoing, Stokkup may freely assign the Terms of Use, and
      the rights and obligations therein, without your consent, including but not limited to, in connection with a
      merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets. Subject to the
      foregoing, the Terms of Use shall bind and inure to the benefit of the parties, their respective successors and
      permitted assigns.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entire
      Agreement</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This
      Agreement, including the documents incorporated herein by reference, is the entire understanding and agreement
      between Stokkup and you with respect to the subject matter hereof.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Miscellaneous</span>
  </p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The
      invalidity or unenforceability of any provision of this Agreement shall not affect the validity or enforceability
      of any other provision. In the event that any provision of this Agreement is found to be invalid or unenforceable,
      this Agreement shall be construed in accordance with its terms as if the invalid or unenforceable provision was
      not contained therein. No delay or failure by Stokkup to enforce any provision of this Agreement shall be a waiver
      of any of our rights under this Agreement. Stokkup may assign this Agreement, and its rights and duties
      thereunder, to any party at any time without notice to you. You may not assign this Agreement or rights and duties
      thereunder without the prior consent of Stokkup.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Digital
      Millennium Copyright Act Notice Policy</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We
      respect the intellectual property rights of others, and ask you to do the same. If you believe that your work has
      been posted on the Site in a way that constitutes copyright infringement, please contact us at the address below
      and provide the following information: (a) the identity of the copyrighted work that you claim has been infringed,
      or, if multiple copyrighted works are covered by the notice, a representative list of the copyrighted works that
      you claim have been infringed via the Site; (b) identification of the material that you claim is infringing
      (including sufficient information so that we may locate the material on the Site, such as the URL where such
      material may be found on the Site); (c) your street or mailing address, telephone number, and, if available, email
      address; (d) the following affirmation (if true): &quot;I hereby state that I have a good faith belief that the
      disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g.,
      fair use).&quot;; (e) the following affirmation (if true): &quot;I hereby state that the above information in this
      Notice is accurate and, under penalty of perjury, that I am the copyright owner, or authorized to act on behalf of
      the owner of the copyright or of the exclusive right under the copyright allegedly infringed.&quot;; (f)
      electronic or physical signature of the copyright owner or of a person authorized to act on behalf of the owner of
      the copyright or of any exclusive right under the copyright.</span></p>
  <p><br></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Company&rsquo;s
      designated agent for notice of copyright infringement can be reached at:</span></p>
  <p><br></p>
  <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
      style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">tech@culturestudio.net</span>
  </p>
</section>