import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, firstValueFrom, map, of, switchMap, tap } from "rxjs";
import { getAllUserClients, getAllUserClientsSuccess, getAllUserClientsFailure, getAllUserDecorators, getAllUserDecoratorsSuccess, getAllUserDecoratorsFailure, getAllAffiliateDecorators, getAllAffiliateDecoratorsSuccess, getAllAffiliateDecoratorsFailure, setUserClientContext, setUserClientContextSuccess, setUserClientContextFailure } from "src/app/core/state/user/user.actions";
import { DecoratorResponse } from "../../models/decorator";
import { ClientResponse, IClientData } from "../../models/client";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { ActivatedRoute, Route, Router } from "@angular/router";

@Injectable()
export class UserEffects {
    userGetAllClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllUserClients),
            switchMap((action) => this.httpClient.get(`${environment.apiUrl}choose/client/${action.decoratorId}`)
                .pipe(
                    switchMap((userClients: ClientResponse) => [
                        getAllUserClientsSuccess({userClients})
                    ]),
                    catchError((error) => {
                        console.error(error)
                        return of(getAllUserClientsFailure({error}))
                    })
                )
            )
        )
    )

    affiliateGetAllDecorators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllAffiliateDecorators),
            switchMap((action) => this.httpClient.get(`${environment.apiUrl}choose/affiliate-decorator`)
                .pipe(
                    map((affiliateDecorators: DecoratorResponse) => getAllAffiliateDecoratorsSuccess({affiliateDecorators})),
                    catchError((error) => of(getAllAffiliateDecoratorsFailure({error})))
                )
            )
        )
    )

    userGetAllDecorators$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllUserDecorators),
            switchMap((action) => this.httpClient.get(`${environment.apiUrl}choose/decorator`)
                .pipe(
                    map((userDecorators: DecoratorResponse) => getAllUserDecoratorsSuccess({userDecorators})),
                    catchError((error) => of(getAllUserDecoratorsFailure({error})))
                )
            )
        )
    )


    userSetClientContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setUserClientContext),
            switchMap((action) => this.httpClient.post(`${environment.apiUrl}choose/set-context`, {decorator_id: action.decorator_id, client_id: action.client.id})
                .pipe(
                    switchMap((userClientContext: IClientData) => [
                        setUserClientContextSuccess({userClientContext})
                    ]),
                    catchError((error) => of(setUserClientContextFailure({error})))
                )
            )
        )
    )

    userSetClientContextRedirect$ = createEffect( () =>
        this.actions$.pipe(
            ofType(setUserClientContextSuccess),
            tap(async () => {
                const queryParams = await firstValueFrom(this.route.queryParams)
                if (queryParams.redirect) {
                    this.router.navigateByUrl(queryParams.redirect)
                } else {
                    this.router.navigate(['/catalog'], {queryParams})
                }
            })
        ),
        { dispatch: false }
    )

    constructor(
        private actions$: Actions,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private router: Router
    ){}
}
