import { Component, OnInit } from '@angular/core';
import { ErrorsService } from './core/services/errors/errors.service';
import { GlobalLoaderService } from '@core/services/global-loader.service';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'cs-platform';
  public constructor(
    public errorService: ErrorsService,
    public globalLoaderService: GlobalLoaderService,
    private auth: AuthService
  ){}
  
  async ngOnInit() {
    // Handle the redirect and retrieve custom data from appState$
    const auth0State = await firstValueFrom(this.auth.appState$)
    // Redirect to the intended page with query parameters
    if (auth0State.redirect) {
      window.location.href = auth0State.redirect;
    }
  }
}
