import { Injectable } from '@angular/core';
import { Client } from "../../models/client";
import { Decorator } from "../../models/decorator";
import { EntityBuilder } from '@decahedron/entity';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService{
  protected userKey: string;
  protected activeDecoratorKey: string;
  protected activeClientKey: string;
  protected driver: any;
  protected activeMemory: Map<string,any>;

  constructor() {
    this.userKey = '_user';
    this.activeDecoratorKey = '_active_decorator';
    this.activeClientKey = '_active_client';
    this.driver = localStorage;
    this.activeMemory = new Map();
  }

  public has(key){
    return this.activeMemory.has(key) || this.driver.getItem(key) !== null;
  }

  public setItem(key:string, value: string): string {
    this.activeMemory.set(key, value);
    this.driver.setItem(key, value);
    return this.activeMemory.get(key);
  }

  public getItem(key:string): string {
    if(this.activeMemory.has(key)){
      if(this.driver.getItem(key) === null){
        this.driver.setItem(key, this.activeMemory.get(key));
      }
    }
    else if(this.driver.getItem(key) !== null){
      this.activeMemory.set(key, this.driver.getItem(key));
    }

    return this.activeMemory.get(key);
  }

  public removeItem(key:string): void {
    if(this.activeMemory.has(key)){
      this.activeMemory.delete(key);
    }
    this.driver.removeItem(key);
  }

  public setObject(key:string, value: Object): Object {
    this.activeMemory.set(key, value);
    this.driver.setItem(key, JSON.stringify(value));
    return this.activeMemory.get(key);
  }

  public getObject(key:string): Object {
    if(this.activeMemory.has(key)){
      if(this.driver.getItem(key) === null){
        this.driver.setItem(key, JSON.stringify(this.activeMemory.get(key)));
      }
    }
    else if(this.driver.getItem(key) !== null){
      this.activeMemory.set(key, JSON.parse(this.driver.getItem(key)));
    }

    return this.activeMemory.get(key);
  }

  public removeObject(key:string): void {
    this.activeMemory.delete(key);
    this.driver.removeItem(key);
  }

  public setActiveDecorator(decorator): Observable<Decorator>{
    this.setObject(this.activeDecoratorKey, decorator);
    return of(decorator);
  }

  public getActiveDecorator(): Decorator{
    if(!this.has(this.activeDecoratorKey)){
      return null;
    }
    return EntityBuilder.buildOne<Decorator>(Decorator, this.getObject(this.activeDecoratorKey));
  }

  public setActiveClient(client): Observable<Client>{
    this.setObject(this.activeClientKey, client);
    return of(client);
  }

  public getActiveClient(): Client{
    if(!this.has(this.activeClientKey)){
      return null;
    }
    return EntityBuilder.buildOne<Client>(Client, this.getObject(this.activeClientKey));
  }

  public flushUser():void{
    this.removeItem(this.userKey);
    this.removeItem(this.activeDecoratorKey);
    this.removeItem(this.activeClientKey);
  }
}
