import { Entity } from '@decahedron/entity';

export class Client extends Entity{
  public id: number = null;
  public decoratorId: number = null;
  public name: string = null;
  public address: string = null;
  public city: string = null;
  public state: string = null;
  public postalCode: string = null;
  public phone: string = null;
  public email: string = null;
  public avatarImg: string = null;
  public avatarTwoChar: string = null;
  public account_manager: number = null;
  public salesperson: number = null;
  public salespricegrid: number = null;
  public created_date: string = null;
}

export interface ClientResponse {
  success: boolean,
  data: [Client],
  message: string,
  pagination?: Object
}

export interface IClientData {
  client_id: number,
  decorator_id: number
}
export interface ICreateClientContact {
  client_id: number,
  first_name: string,
  last_name: string,
  email: string
}
