import { createAction, props } from "@ngrx/store";
import { Client, ClientResponse, IClientData } from "../../models/client";
import { DecoratorResponse } from "../../models/decorator";

// Get All User Decorators
export const getAllUserDecorators = createAction(
    '[User] Get All User Decorators'
)
export const getAllUserDecoratorsSuccess = createAction(
    '[User] Get All User Decorators Success',
    props<{ userDecorators: DecoratorResponse }>()
)
export const getAllUserDecoratorsFailure = createAction(
    '[User] Get All User Decorators Failure',
    props<{ error: string }>()
)

// Get All Affiliate Decorators 
export const getAllAffiliateDecorators = createAction(
    '[User] Get All Affiliate Decorators'
)
export const getAllAffiliateDecoratorsSuccess = createAction(
    '[User] Get All Affiliate Decorators Success',
    props<{ affiliateDecorators: DecoratorResponse }>()
)
export const getAllAffiliateDecoratorsFailure = createAction(
    '[User] Get All Affiliate Decorators Failure',
    props<{ error: string }>()
)

// Get All User Client
export const getAllUserClients = createAction(
    '[User] Get All User Clients',
    props<{ decoratorId: number}>()
)
export const getAllUserClientsSuccess = createAction(
    '[User] Get All User Clients Success',
    props<{ userClients: ClientResponse }>()
)
export const getAllUserClientsFailure = createAction(
    '[User] Get All User Clients Failure',
    props<{ error: string }>()
)

// Set User Client Context
export const setUserClientContext = createAction(
    '[User] Set User Client Context',
    props<{ client: Client, decorator_id: number}>()
)
export const setUserClientContextSuccess = createAction(
    '[User] Set User Client Context Success',
    props<{ userClientContext: IClientData }>()
)
export const setUserClientContextFailure = createAction(
    '[User] Set User Client Context Failure',
    props<{ error: string }>()
)